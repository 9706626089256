import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['baseFeatures', 'selectVariables', 'selectLang', 'trixEditor', 'content', 'emailObject']
  static values = { lang: String }

  newLangSelected = ''

  connect() {
    setTimeout(() => {
      this.insertHrefDescription()
    }, 500)
  }

  insertHrefDescription() {
    document.querySelectorAll('.href-warning').forEach(elem => elem.remove())
    const hrefElems = document.querySelectorAll('.trix-dialog--link')

    hrefElems.forEach(elem => {
      elem.insertAdjacentHTML('beforeend', `<p class="href-warning tw-text-gray tw-mt-1"> Le lien doit démarrer par https://…</p>`)
    })
  }

  selectLangTargetConnected() {
    this.langSelected = this.langValue || this.selectLangTarget.value
    this.toggleContentLocales(this.langSelected)
  }

  getVariable() {
    const variableGet = ` {{${this.selectVariablesTarget.value}}} `

    if (this.trixEditorTargets.length) {
      const trixEditor = this.trixEditorTargets.find(target => target.checkVisibility() == true).editor
      trixEditor.insertHTML(variableGet)
    } else {
      this.insertInTextArea(variableGet)
    }
    this.selectVariablesTarget.value = ''
  }

  textAreaIsFocused(event) {
    this.focusedTextArea = event.target
    this.selectionStart = this.focusedTextArea.selectionStart
    this.selectionEnd = this.focusedTextArea.selectionEnd
  }

  insertInTextArea(tag) {
    this.initialValue = this.focusedTextArea.value
    this.focusedTextArea.value =
      this.initialValue.slice(0, this.selectionStart) + tag + this.initialValue.slice(this.selectionEnd, this.initialValue.length)
    this.focusedTextArea.dispatchEvent(new Event('input'))
  }

  getLangSelectedOnChange(event) {
    this.newLangSelected = event.currentTarget.value
    this.toggleContentLocales(this.newLangSelected)
  }

  modalBtnValidChangeLang(event) {
    if (event.currentTarget.dataset.validChangeLang === 'true') {
      this.selectLangTarget.value = this.newLangSelected
    } else {
      this.selectLangTarget.value = this.langSelected
    }
  }

  toggleContentLocales(locale) {
    const targets = [...this.contentTargets, ...this.emailObjectTargets]
    targets.forEach(target => {
      target.classList.toggle('tw-hidden', target.dataset.locale !== locale)
    })
  }
}
