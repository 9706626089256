export class ManageSource {
  constructor() {
    this.sources = [
      'abritel_homeaway',
      'agency',
      'airbnb',
      'beds24',
      'booking',
      'bookingsync',
      'direct',
      'expedia',
      'itea',
      'lodgify',
      'others',
      'secure_immo',
      'superhote',
      'gens_de_confiance',
      'website'
    ]
  }

  dispatchStaySource(stays) {
    let events = document.querySelectorAll('.event-calendar')
    events.forEach(ev => {
      stays.forEach(stay => {
        if (ev.classList.contains(`${stay.source}`) && !ev.classList.contains('null')) {
          const alreadyHasSource = ev.querySelectorAll('.source-box')
          if (alreadyHasSource.length < 1) {
            const srcBox = document.createElement('div')
            const srcIcon = document.createElement('img')
            srcBox.classList.add('source-box', 'p-0.5', `bg-${stay.source}`)
            srcIcon.src = `${stay.sourceImageUrl}`
            srcBox.appendChild(srcIcon)
            ev.appendChild(srcBox)
          }
        }
      })
    })
  }
}
