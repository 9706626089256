import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleInput', 'checkboxList']
  static values = { checked: Boolean }
  connect() {
    this.isChecked = this.checkedValue
    this.toggleClasses()
  }

  toggle() {
    this.isChecked = !this.isChecked
    if (!this.isChecked) {
      this.desactivateCheckboxes()
    }
    this.toggleClasses()
  }

  desactivateCheckboxes() {
    this.checkboxListTarget.querySelectorAll('input[type=checkbox]').forEach(cbx => {
      cbx.checked = false
    })
  }

  toggleClasses() {
    if (this.isChecked) {
      this.toggleInputTarget.classList.add('switch-toggle--on')
      this.toggleInputTarget.classList.remove('switch-toggle--off')
      this.checkboxListTarget.classList.remove('tw-hidden')
    } else {
      this.toggleInputTarget.classList.add('switch-toggle--off')
      this.toggleInputTarget.classList.remove('switch-toggle--on')
      this.checkboxListTarget.classList.add('tw-hidden')
    }
  }
}
