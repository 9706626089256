import { format, parseISO } from 'date-fns'
import { French } from 'flatpickr/dist/l10n/fr'
import LocalStore from 'helpers/localStore.js'
import StateDomElement from 'helpers/stateDomElement.js'
import { DispatchDataStayCalendar } from './dispatchDataStayCalendar.js'
import { ManageDatesFlatpickr } from './manageDatesFlatpickr.js'
import { intervalBetweenTwoDates, arrangeDatesChronologically } from 'utils/helpersDate.js'
import { getValueOfParamsInUrl } from 'utils/urlHelpers'

export class ManageUserExperiences {
  constructor() {
    this.cellBlocked = ''
    this.datesBlockedArrStartDate = []
    this.locale = null
    this.store = new LocalStore()
    this.stateDom = new StateDomElement()
    this.dispatchData = new DispatchDataStayCalendar()
    if (window.I18n === 'fr') this.locale = French
  }

  addDescInTextArea(descArr) {
    let isNecessaryToAddDesc = false
    const refDesc = descArr[0]
    descArr.forEach(desc => {
      desc === refDesc ? (isNecessaryToAddDesc = true) : (isNecessaryToAddDesc = false)
    })
    isNecessaryToAddDesc ? this.store.set('descriptionReason', refDesc) : this.store.set('descriptionReason', '')
  }

  addIconByTypePattern(cell, newCell) {
    const newIcon = document.createElement('i')
    if (cell.classList.contains('is-blocked-owner_occupation')) {
      newIcon.classList.add('fa-solid', 'fa-user-tie')
    } else if (cell.classList.contains('is-blocked-under_construction')) {
      newIcon.classList.add('fa-solid', 'fa-hammer')
    } else {
      newIcon.classList.add('fa-solid', 'fa-circle-xmark')
    }
    newCell.appendChild(newIcon)
  }

  /**
   * Updates the localStorage based on the reference length (array of locked dates selected by the user)
   * compared to each array (corresponding to a particular reason on the selected date range)
   * @param {Number} refLength
   * @param {Number} ownerLength
   * @param {Number} constructionLength
   * @param {Number} otherLength
   */

  addReasonInLocalStorage(refLength, ownerLength, constructionLength, otherLength) {
    let reason = ''
    switch (refLength) {
      case ownerLength:
        reason = 'owner_occupation'
        break
      case constructionLength:
        reason = 'under_construction'
        break
      case otherLength:
        reason = 'other'
        break
      default:
        reason = ''
    }
    this.store.set('valueReason', reason)
  }

  /**
   * Date 21/11/2024
   * Add the description for each unavailability
   * @param {Array} filterData - Array of Object. Each object corresponds to an unavailable date
   * @param {Array} cells - Array of HTMLElements. Each element of the table corresponds to a table unavailable in the calendar
   */
  addToolTipContent(filterData, cells) {
    arrangeDatesChronologically(filterData, 'startDate')
    cells.forEach((cell, index) => {
      let patternReasonDesc = cell.querySelector('.tooltip-pattern-container .tooltip-pattern')
      patternReasonDesc.innerText = filterData[index].description
      this.translationToolTipReason(cell, filterData[index].reason)
    })
  }

  /**
   * Date 20/11/2024
   * When the user clicks outside the calendar, and the previous selection contains blocked cells, I return them to their original color.
   */
  blockedCellsColorClass() {
    const blockedCells = document.querySelectorAll('.is-blocked-container')
    blockedCells.forEach(cell => {
      if (this.stateDom.hasClass(cell, 'tw-bg-gray-500')) this.stateDom.removeClass(cell, ['tw-bg-gray-500'])
    })
  }

  /**
   * Date 20/11/2024
   * Retrieves all cells corresponding to dates in the calendar.
   * Keeps only the cell corresponding to today and applies style to it.
   */
  changeColorOfTodayCell() {
    const today = new Date()
    const refDate = format(today, "yyyy-MM-dd'T00:00:00'")
    const slotsDay = document.querySelectorAll('#stayCalendar .fc-day')
    const slotToday = Array.from(slotsDay).find(el => this.stateDom.getAt(el, 'data-date') === refDate)
    const slotTodayLink = slotToday.querySelector('a')
    this.stateDom.addClass(slotToday, ['tw-bg-purple', 'current-day'])
    this.stateDom.addClass(slotTodayLink, ['tw-text-white', 'tw-pointer-events-none'])
  }

  /**
   * Allows you to check and count the number of available or blocked boxes in the user's selection.
   * @param {Object} selectedData - Data corresponding to user-selected boxes
   * @param {Array} cellsBlocked - Array of cells blocked in the calendar (work, owner, other...)
   */

  checkDatesInRowSelected(selectedData, cellsBlocked) {
    const flatpickrClass = new ManageDatesFlatpickr()
    const startAndEndDatesSelectedInMs = flatpickrClass.selectedDatesStartAndEndInMs(selectedData.start, selectedData.end)
    if (cellsBlocked) {
      const cellsBlockedInMs = flatpickrClass.getDisabledDatesInRowSelectedInMs(cellsBlocked)
      this.countAvailableAndUnavailableDates(startAndEndDatesSelectedInMs, cellsBlockedInMs)
    } else {
      this.countAvailableAndUnavailableDates(startAndEndDatesSelectedInMs, [])
    }
  }

  checkInputAvailableOrUnavailable(counter) {
    const unAvailableRadio = document.getElementById('unavailable')
    const availableRadio = document.getElementById('available')
    if (counter.availableDate === 0) {
      unAvailableRadio.querySelector('input').click()
    } else if (counter.unAvailableDate === 0) {
      if (availableRadio !== null) availableRadio.querySelector('input').click()
    } else {
      if (unAvailableRadio !== null) {
        this.stateDom.removeClass(unAvailableRadio, ['form-radio--checked'])
        this.stateDom.removeAt(unAvailableRadio, ['checked'])
      }
      if (availableRadio !== null) {
        this.stateDom.removeClass(availableRadio, ['form-radio--checked'])
        this.stateDom.removeAt(availableRadio, ['checked'])
      }
    }
  }

  /**
   * Enables you to count the number of days between two selected dates.
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns - The number of days between the selected dates
   */
  checkNumberOfDaysBetweenTwoDates(startDate, endDate) {
    let dayCounter = 0
    const differenceInTime = new Date(endDate).getTime() - new Date(startDate).getTime()
    dayCounter = Math.round(differenceInTime / (1000 * 3600 * 24))
    return dayCounter
  }

  /**
   * This function completes the form after the user has changed the dates selected in the datepicker.
   */
  completeFormAfterChange() {
    const counterData = this.store.get('counterAvailable')
    this.updateCounter(counterData)
    this.checkInputAvailableOrUnavailable(counterData)
    this.completeSelectAndTextAreaAvailability(counterData)
  }

  completeLinkAvailability(link, linkValue) {
    const staySelected = this.store.get('staySelected')
    link.href = `${linkValue}?end_date=${staySelected.endDate}&housing_id=${staySelected.housingId}&start_date=${staySelected.startDate}`
  }

  /**
   * 08/10/2024
   * Get Value Reason and DescriptionReason in LocalStorage, and complete select and textarea in availability form
   * @param {Object} counter - Object with two keys, one counter availability and one counter unavailability
   */
  completeSelectAndTextAreaAvailability(counter) {
    const selectReason = document.getElementById('rate_and_availability_reason')
    const textAreaDescReason = document.getElementById('rate_and_availability_description')
    if (selectReason !== null) selectReason.value = this.store.get('valueReason')
    if (textAreaDescReason !== null) textAreaDescReason.value = this.store.get('descriptionReason')
    if (counter.availableDate !== 0 && counter.unAvailableDate !== 0) {
      const availableInput = document.getElementById('available')
      this.stateDom.removeAt(availableInput.querySelector('input'), 'checked')
      this.stateDom.removeClass(availableInput, ['form-radio--checked'])
      const formReason = document.querySelector('[data-forms--availability-stays-target="formReason"]')
      this.stateDom.showElTailwind(formReason)
      formReason.querySelector('select').value = ''
      textAreaDescReason.value = ''
    }

    if (counter.availableDate === null && counter.unAvailableDate === 1) {
      const unAvailableInput = document.getElementById('unavailable')
      selectReason.value = this.store.get('valueReason')
      textAreaDescReason.value = this.store.get('descriptionReason')
      this.stateDom.setAt(unAvailableInput.querySelector('input'), 'checked')
      this.stateDom.addClass(unAvailableInput, ['form-radio--checked'])
    }
  }

  /**
   * Date 09/10/2024
   * Calculates the number of available and unavailable dates
   * in the period selected by the user
   * @param {Array} datesSelected - Dates selected by user in timestamps
   * @param {Array} datesBlocked - Dates blocked in calendar, in timestamps
   */

  countAvailableAndUnavailableDates(datesSelected, datesBlocked) {
    const intervalObj = intervalBetweenTwoDates(datesSelected[0], datesSelected[1])
    let counter = { availableDate: 0, unAvailableDate: 0 }
    datesBlocked.forEach(date => {
      if (datesSelected[0] <= date && datesSelected[1] >= date) {
        counter.unAvailableDate++
      }
    })
    const available = intervalObj.days - counter.unAvailableDate
    counter.availableDate = available
    this.store.set('counterAvailable', counter)
  }

  createStayOrNot(selection) {
    let timeBetweenStartEndDate = selection.end.getTime() - selection.start.getTime()
    let daysBetweenStartEndDate = Math.round(timeBetweenStartEndDate / (1000 * 3600 * 24))
    let calMenuCtas = document.getElementById('createStay')
    daysBetweenStartEndDate === 1 ? this.stateDom.addClass(calMenuCtas, ['is-disabled']) : this.stateDom.removeClass(calMenuCtas, ['is-disabled'])
  }

  createTooltipAndClone(cell, data, toolTipNode) {
    const tooltip = toolTipNode.cloneNode(true)
    cell.appendChild(tooltip)
    const getRowOfArrData = data[cell.dataset.resourceId]
    const getCellInRows = document.querySelectorAll(`.is-blocked-container[data-resource-id="${cell.dataset.resourceId}"]`)
    this.addToolTipContent(getRowOfArrData, getCellInRows)
  }

  /**
   * Create a div and add the icon corresponding to the reason for unavailability
   */

  createUnavailableCellWithIcon(cell) {
    const newCellParent = cell.closest('.fc-timeline-event-harness')
    const newCell = document.createElement('div')
    cell.style.opacity = 0
    cell.style.zIndex = -1
    this.stateDom.addClass(newCell, ['is-blocked-container'])
    this.stateDom.addClass(newCellParent, ['cell-blocked'])
    newCellParent.appendChild(newCell)
    const idResource = newCellParent.closest('.fc-timeline-lane.fc-resource').dataset.resourceId
    this.stateDom.setAt(newCell, 'data-resource-id', idResource)
    return newCell
  }

  /**
   * Date 07/10/2024
   * Display each blocked cell and composite it, with an icon and a tooltip with reason description inside
   */
  displayBlockedCell() {
    const cellBlocked = document.querySelectorAll('.is-blocked')
    const toolTipRef = document.querySelector('.tooltip-pattern-container')
    const unavailableDataObj = this.store.get('unavailableData')
    cellBlocked.forEach(cell => {
      const newCell = this.createUnavailableCellWithIcon(cell)
      this.addIconByTypePattern(cell, newCell)
      this.createTooltipAndClone(newCell, unavailableDataObj, toolTipRef)
    })
    this.splitCellsBlocked(document.querySelectorAll('.cell-blocked'))
  }

  /**
   * Date 20/11/2024
   * Filters calendar homes based on user search
   * @param {Array} housings - An object array showing the various units in the calendar
   * @returns Array. An object array showing the various dwellings in the calendar after filtering has been applied
   */
  filterHousingInCalendar(housings) {
    const query = getValueOfParamsInUrl(window.location.href, 'housing_search')
    const filteredHousings = housings.filter(housing => housing.namePrivate.toLowerCase().includes(query.toLowerCase()))
    this.hideHousingInMultiselect(filteredHousings)
    return filteredHousings
  }

  /**
   * Date 05/11/2024
   * This method reduces the table of unavailable dates in the row selected by the user.
   * In this way, you can concentrate on only those dates that correspond to the flatpickr
   * selection, and dynamically fill in the availability form.
   * @param {Array} dataInRow - An object array corresponding to the user's selection in the date picker
   * @param {Array} datesSelected - An array with dates selected by the user
   */
  filterUnavailabilitiesDataInRowSelected(dataInRow, datesSelected) {
    dataInRow.forEach(data => {
      const startDate = format(parseISO(data.startDate), 'yyyy-MM-dd')
      data.startDate = startDate
    })
    const dataMatchWithSelectedDates = dataInRow.filter(data => data.startDate === datesSelected[0] || data.startDate === datesSelected[1])
    this.dispatchData.dispatchReasonsInArray(dataMatchWithSelectedDates)
  }

  /**
   * Date 29/11/2024
   * Hide housings that are not in the multiselect
   * @param {Array} filterHousings - Array of object who contains all housings filtered
   */
  hideHousingInMultiselect(filterHousings) {
    const allCheckboxes = document.querySelectorAll('.checkbox-list-column')
    let housingsId = []
    filterHousings.forEach(housing => {
      housingsId.push(housing.id)
    })
    allCheckboxes.forEach(checkbox => {
      if (!housingsId.includes(this.stateDom.getAt(checkbox, 'data-id-check'))) {
        checkbox.style.display = 'none'
      }
    })
  }

  /**
   * Date 21/11/2024
   * When I click outside the tooltip and my click doesn't correspond to an event,
   * I hide the tooltip.
   * @param {Event} event - Event egal to click of user
   * @param {HTMLElement} tooltip - .calendar-list it's tooltip with ctas menu
   * @param {Event} onClose - Event use to close tooltip
   */
  outsideClickOfTooltip(event, tooltip, onClose) {
    if (!this.stateDom.hasClass(tooltip, 'tw-hidden') && !tooltip.contains(event.target) && !event.target.closest('.fc-event')) {
      this.stateDom.hideElTailwind(tooltip)
      this.blockedCellsColorClass()
      onClose()
    }
  }


  /**
   * Date 07/10/2024
   * @param {Array} cells - All cells blocked in calendar
   * Split all cells blocked in two parts. To condition if tooltip display by right position
   * or left position and avoid bad display because overflow
   */
  splitCellsBlocked(cells) {
    const secondPartOfCalendar = []
    cells.forEach(cell => {
      const rightValueString = cell.style.right
      const rightValueNum = Number(rightValueString.substring(rightValueString.indexOf('-') + 1, rightValueString.lastIndexOf('p')))
      if (rightValueNum > 767) {
        secondPartOfCalendar.push(cell)
      }
    })
    this.changePositionOfTooltip(secondPartOfCalendar)
  }

  changePositionOfTooltip(cells) {
    cells.forEach(cell => {
      const cellTooltipContainer = cell.querySelector('.tooltip-pattern-container')
      this.stateDom.addClass(cellTooltipContainer, ['is-split'])
    })
  }

  /**
   * Date 07/10/2024
   * Check if in blocked cells selected, we have differents pattern or only pattern,
   * and add it in textArea of availability form
   * @param {Array} ownerDesc - Contains description of owner pattern unavailabilities
   * @param {Array} constructionDesc - Contains description of construction pattern unavailabilities
   * @param {Array} otherDesc - Contains description of other pattern unavailabilities
   */
  displayDescriptionInTextArea(ownerDesc, constructionDesc, otherDesc) {
    const currentReason = this.store.get('valueReason')
    if (currentReason === 'owner_occupation' && constructionDesc.length === 0 && otherDesc.length === 0) {
      this.addDescInTextArea(ownerDesc)
    } else if (currentReason === 'under_construction' && ownerDesc.length === 0 && otherDesc.length === 0) {
      this.addDescInTextArea(constructionDesc)
    } else if (currentReason === 'other' && ownerDesc.length === 0 && constructionDesc.length === 0) {
      this.addDescInTextArea(otherDesc)
    } else {
      this.store.set('descriptionReason', '')
    }
  }

  /**
   * Format and reduce object who contain selected dates by user, and register it in the localStorage
   * @param {Object} stayObj - Object of date selected by the user
   */
  getDataStay(stayObj) {
    const flatpickrClass = new ManageDatesFlatpickr()
    const stayDates = flatpickrClass.formattedDateSelectedByUser([stayObj.start, stayObj.end.setDate(stayObj.end.getDate() - 1)])
    const selectionDataStay = {
      startDate: stayDates[0],
      endDate: stayDates[1],
      housingId: stayObj.resource._resource.id
    }
    this.store.set('staySelected', selectionDataStay)
  }

  /**
   * Prepares incoming data and separates it into sub-objects for easier use
   * @param {Object} data
   */
  manageBlockedCell(data) {
    const unavailableCells = this.store.get('unavailableData')
    const rowId = this.store.get('idRowSelected')
    const blockedCellsRow = unavailableCells[rowId]
    this.checkDatesInRowSelected(data, blockedCellsRow)
    this.dispatchData.dispatchReasonsInArray(blockedCellsRow)
  }

  /**
   * Date 07/10/2024
   * Separates the global arrays into sub-arrays to facilitate future data manipulation
   * @param {Array} reasons - All reasons why cells blocked
   * @param {Array} descriptions - All description why cells blocked
   */
  splitReasons(reasons, descriptions) {
    let reasonOwner = []
    let reasonOwnerDescription = []
    let reasonConstruction = []
    let reasonConstructionDescription = []
    let reasonOther = []
    let reasonOtherDescription = []

    reasons.forEach((reason, index) => {
      switch (reason) {
        case 'owner_occupation':
          reasonOwner.push(reason)
          reasonOwnerDescription.push(descriptions[index])
          break
        case 'under_construction':
          reasonConstruction.push(reason)
          reasonConstructionDescription.push(descriptions[index])
          break
        default:
          reasonOther.push(reason)
          reasonOtherDescription.push(descriptions[index])
      }
    })
    this.addReasonInLocalStorage(reasons.length, reasonOwner.length, reasonConstruction.length, reasonOther.length)
    this.displayDescriptionInTextArea(reasonOwnerDescription, reasonConstructionDescription, reasonOtherDescription)
  }

  /**
   * Date 07/10/2024
   * Translates the reason into French if the user has a French browser.
   * @param {HTMLElement} cell - Current cell blocked
   * @param {String} reasonTitle
   */
  translationToolTipReason(cell, reasonTitle) {
    let patternReason = cell.querySelector('p')
    if (window.I18n == 'fr') {
      switch (reasonTitle) {
        case 'under_construction':
          patternReason.innerText = 'Travaux'
          break
        case 'owner_occupation':
          patternReason.innerText = 'Propriétaire'
          break
        default:
          patternReason.innerText = 'Autre'
      }
    } else {
      switch (reasonTitle) {
        case 'under_construction':
          patternReason.innerText = 'Construction'
          break
        case 'owner_occupation':
          patternReason.innerText = 'Owner'
          break
        default:
          patternReason.innerText = 'Other'
      }
    }
  }

  /**
   * Date 08/10/2024
   * Update counter in availability form, after to have get localStorage
   * @param {Object} counter - Object with 2 key, available and unavailable counter
   */
  updateCounter(counter) {
    const counterAvailable = document.getElementById('availableCounter')
    const counterUnAvailable = document.getElementById('unAvailableCounter')
    if (counterAvailable !== null && counterUnAvailable !== null) {
      counterAvailable.innerText = counter.availableDate
      counterUnAvailable.innerText = counter.unAvailableDate
    }

    if (counter.availableDate === null && counter.unAvailableDate === 0) counterAvailable.innerText = 1
    if (counter.availableDate === null && counter.unAvailableDate >= 1) counterAvailable.innerText = 0
  }
}
