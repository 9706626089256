import StateDomElement from 'helpers/stateDomElement'
export default class SearchableListManager {
  constructor(dataList) {
    this.dataList = dataList
    this.stateDom = new StateDomElement()
  }

  /**
   * @param {string} query
   * Filter the DOM elements that has the js-item class in the dataList container.
   * The elements that contain the query in its textContent will have the class
   * 'tw-hidden' removed and the other will have it added.
   */
  filterInDOM(query) {
    const items = this.dataList.querySelectorAll('.js-item')

    items.forEach(item => {
      const text = item.textContent.toLowerCase()
      text.includes(query.toLowerCase()) ? this.stateDom.removeClass(item, ['d-none']) : this.stateDom.addClass(item, ['d-none'])
    })
  }

  /**
   * Updates checkbox status.
   * @param {boolean} selectAll - If true, all boxes are checked, otherwise all are unchecked.
   */
  toggleSelection(selectAll) {
    const items = this.dataList.querySelectorAll(".js-items-list input[type='checkbox']")

    items.forEach(checkbox => {
      checkbox.checked = selectAll
      checkbox.dispatchEvent(new Event('change'))
    })
  }

  toggleSelectionAll(checkboxMaster) {
    const items = this.dataList.querySelectorAll(".js-items-list input[type='checkbox']")
    checkboxMaster.checked = true

    items.forEach(checkbox => {
      if (!checkbox.checked) checkboxMaster.checked = false
    })
  }
}
