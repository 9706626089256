import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputField', 'selectUnit', 'hiddenField']
  static values = {
    conversionMode: String
  }

  connect() {
    this.selectUnitTarget.value = 'days'
  }

  updateHiddenField() {
    const inputValue = parseInt(this.inputFieldTarget.value, 10) || 0
    let calculatedValue = inputValue

    if (this.selectUnitTarget.value === 'days') {
      if (this.conversionModeValue === 'minutes_to_days') {
        calculatedValue = this.daysToMinutes(inputValue)
      } else {
        calculatedValue = this.daysToHours(inputValue)
      }
    }

    this.hiddenFieldTarget.value = calculatedValue
  }

  daysToHours(days) {
    return parseInt(days) * 24
  }

  hoursToDays(hours) {
    return parseInt(hours) / 24
  }

  daysToMinutes(days) {
    return this.daysToHours(days) * 60
  }
}
