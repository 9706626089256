import { Controller } from '@hotwired/stimulus'
import SearchableListManager from 'utils/searchable_list_manager'

export default class extends Controller {
  static targets = ['input', 'dataList', 'checkboxMaster']

  connect() {
    this.listManager = new SearchableListManager(this.dataListTarget)
  }

  /**
   * Searches in the DOM elements of the list for elements that contain the text
   * written in the input. If the query is empty, it shows all elements.
   */
  search() {
    const query = this.inputTarget.value.trim()
    this.listManager.filterInDOM(query)
  }

  /**
   * Toggle the selection of all items in the list if the data attribute
   * select-all is set to true. Otherwise, toggle the selection of the item
   * that triggered the event.
   *
   * @param {HTMLElementEventMap} event - The event that triggered the
   *                                        toggle of the selection.
   */
  toggleSelection(event) {
    const selectAll = event.currentTarget.dataset.selectAll === 'true'
    this.listManager.toggleSelection(selectAll)
  }

  toggleSelectionAll() {
    if(this.hasCheckboxMasterTarget) this.listManager.toggleSelectionAll(this.checkboxMasterTarget, this.dataListTarget)
  }
}
