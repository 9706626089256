import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['modalContainer']

  connect() {
    this.stateDom = new StateDomElement()
  }

  open() {
    this.stateDom.addClass(this.modalContainerTarget, ['opened', 'tw-overflow-hidden'])
  }

  close() {
    this.stateDom.removeClass(this.modalContainerTarget, ['opened', 'tw-overflow-hidden'])
  }
}