import { Controller } from 'stimulus'
import { apiFetch } from '../../api/jana/apiWrapper'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'totalPrice',
    'touristTax',
    'bankCharges',
    'serviceCharge',
    'sourceInput',
    'housingInput',
    'hostPayment',
    'cleaningFeesInput',
    'commissionableAmount',
    'commissionRate',
    'commissionAmount',
    'commissionableAmountAlert',
    'commissionableNegativeAmountAlert',
    'rowInputs',
    'commissionSection'
  ]

  static values = {
    stayId: { type: Number },
    housing: { type: String, default: '' },
    stayPersisted: { type: Boolean, default: false },
    locale: { type: String, default: 'fr' }
  }

  #housing = null

  async connect() {
    if (this.hasCleaningFeesInputTarget && !this.cleaningFeesInputTarget.value && !this.stayPersistedValue && this.housingValue.length)
      this.selectCleaningFees()
    await this.computeCommission()
    this.debounceTimer = null
  }

  async computeHostPayment() {
    let totalPrice = parseFloat(this.totalPriceTarget.value.replace(',', '.')) || 0
    let touristTax = parseFloat(this.touristTaxTarget.value.replace(',', '.')) || 0
    let bankCharges = parseFloat(this.bankChargesTarget.value.replace(',', '.')) || 0
    let serviceCharge = parseFloat(this.serviceChargeTarget.value.replace(',', '.')) || 0

    let hostPayment = totalPrice - touristTax - bankCharges - serviceCharge

    if (hostPayment >= 0 && !isNaN(hostPayment)) {
      this.hostPaymentTarget.value = this.#formatWithLocale(hostPayment)
    } else {
      this.hostPaymentTarget.value = ''
    }
  }

  computeHelpFields() {
    let totalPriceElement = document.querySelector("input[data-stays--modal-target='totalPrice']")
    let touristTaxElement = document.querySelector("input[data-stays--modal-target='touristTax']")
    let commissionElement = document.querySelector("input[data-stays--modal-target='commission']")
    let bankChargesElement = document.querySelector("input[data-stays--modal-target='bankCharges']")

    totalPriceElement.value = this.totalPriceTarget.value
    touristTaxElement.value = this.touristTaxTarget.value
    commissionElement.value = this.serviceChargeTarget.value
    bankChargesElement.value = this.bankChargesTarget.value
  }

  async selectCleaningFees() {
    this.#housing = this.hasHousingInputTarget && this.housingInputTarget?.value

    if (!this.#housing) return

    const housingPrestations = await apiFetch('housing-prestations', {
      params: {
        filter: {
          housing_id: this.#housing,
          category: 'cleaning'
        }
      }
    })

    const selectedHousingPrestation = housingPrestations.find(elem => elem.housingId == this.#housing)

    if (selectedHousingPrestation && selectedHousingPrestation?.prestationId) {
      let prestation = await apiFetch(`prestations/${selectedHousingPrestation.prestationId}`)

      this.cleaningFeesInputTarget.value = this.#formatWithLocale(parseFloat(prestation.priceIncludingTax))
    } else {
      this.cleaningFeesInputTarget.value = ''
    }

    const housing = await apiFetch(`housings/${this.#housing}`)

    if (housing && housing.commissionId) {
      this.commissionRateTarget.value = housing.commissionId
    } else {
      this.commissionRateTarget.value = ''
    }

    await this.computeCommission()
  }

  async computeCommission() {
    clearTimeout(this.debounceTimer)

    this.debounceTimer = setTimeout(() => {
      post('/invoicing/stays/compute_commission_amount', {
        responseKind: 'turbo-stream',
        body: JSON.stringify({
          stay_id: this.stayIdValue,
          stay: {
            source: this.sourceInputTarget.value,
            cleaning_fees: this.cleaningFeesInputTarget.value,
            service_charge: this.serviceChargeTarget.value,
            tax: this.touristTaxTarget.value,
            bank_charges: this.bankChargesTarget.value,
            total_price: this.totalPriceTarget.value,
            commission_id: this.commissionRateTarget.value,
            host_payment: this.hostPaymentTarget.value
          }
        })
      })
    }, 100)
  }

  dropdownsInputs(e) {
    this.rowInputsTarget.classList.toggle('tw-hidden')
    e.currentTarget.querySelector('svg').classList.toggle('tw-rotate-90')
  }

  #isPresentOrZero(value) {
    if (value || value === 0) return true
  }

  #formatWithLocale(value) {
    if (this.localeValue === 'fr') {
      return value.toFixed(2).replace('.', ',')
    } else {
      return value.toFixed(2)
    }
  }
}
