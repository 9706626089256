import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['timingType', 'triggerType', 'delayOffset', 'executionTime', 'lastMinuteStartDate', 'lastMinuteEndDate']

  toggleElement(element, show) {
    if (!element) return
    element.style.display = show ? '' : 'none'
    if (!show) this.reset(element)
  }

  reset(element) {
    element.querySelectorAll('input').forEach(input => (input.value = ''))
  }
}
