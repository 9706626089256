import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filterTitle', 'customFilter', 'stayProject', 'dateRange']

  connect() {
    this.changeFilterTitle()
  }

  changeFilterTitle() {
    if (this.hasFilterTitleTarget) {
      if (this.hasCustomFilterTarget && this.customFilterTarget.checked && this.hasDateRangeTarget) {
        this.filterTitleTarget.textContent = this.dateRangeTarget.dataset.rangeDate
      } else if (this.hasStayProjectTarget && this.stayProjectTarget.checked) {
        if ($('#statsStaySelect option:selected').length) {
          this.filterTitleTarget.textContent = $('#statsStaySelect option:selected').text()
          if(this.filterTitleTarget.textContent === '') {
            this.filterTitleTarget.textContent = this.filterTitleTarget.dataset.customTitle
          }
        } else if ($('#statsProjectSelect option:selected').length) {
          this.filterTitleTarget.textContent = $('#statsProjectSelect option:selected').text()
        }
      }
    }
  }
}
