import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['triggerElement', 'content', 'emptyMessage', 'selectAllHousing']

  static values = {
    trigger: String
  }

  stateDom = new StateDomElement()
  connect() {
    this.verifyEmptyOptions()
    this.getCheckboxes().forEach(checkbox => {
      checkbox.addEventListener('change', this.verifyEmptyOptions.bind(this))
    })
    this.toggle()
  }

  /**
   * Toggles the visibility of the content
   * and selects all checkboxes and selects all housing when the content is visible
   */
  toggle() {
    this.contentTarget.classList.toggle('tw-hidden', this.triggerElementTarget.value != this.triggerValue)
    if (this.triggerElementTarget.value != this.triggerValue) {
      this.checkAll()
      this.selectAllHousingTarget.checked = true
    } else {
      this.selectAllHousingTarget.checked = false
    }
    this.verifyEmptyOptions()
  }

  /**
   * Gets all the checkboxes in the content element
   * @returns {HTMLCollectionOf<HTMLInputElement>} - All the checkboxes
   */
  getCheckboxes() {
    return this.contentTarget.querySelectorAll('input[type=checkbox]')
  }

  /**
   * Selects all checkboxes
   */
  checkAll() {
    this.selectAllHousingTarget.checked = true
    const checkboxes = this.getCheckboxes()
    checkboxes.forEach(checkbox => {
      checkbox.checked = true
    })
  }

  /**
   * Checks if there are any checked checkboxes and shows/hides the empty message
   * If there are checked checkboxes, hides the empty message
   * If there are no checked checkboxes, shows the empty message and unchecks the select all housing box
   */
  verifyEmptyOptions() {
    const checkboxes = this.getCheckboxes()
    const emptyMsg = this.emptyMessageTarget
    this.stateDom.removeClass(emptyMsg, ['tw-hidden'])

    const hasCheckedBoxes = Array.from(checkboxes).some(checkbox => checkbox.checked)
    hasCheckedBoxes ? this.stateDom.addClass(emptyMsg, ['tw-hidden']) : (this.selectAllHousingTarget.checked = false)
  }
}
