import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['']
  static values = { url: String }

  update(e) {
    e.preventDefault()
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'reload_form')
    hiddenInput.setAttribute('value', true)
    this.element.appendChild(hiddenInput)
    this.element.requestSubmit()
  }
}
