import { Controller } from 'stimulus'
import { apiFetch } from '../../api/jana/apiWrapper'

export default class extends Controller {
  static targets = [
    'taskStay',
    'taskKind',
    'taskDate',
    'manuallyScheduled',
    'taskHousing',
    'tasksTemplate',
    'taskPrestation',
    'taskPricePretax',
    'taskPriceIncludingTax',
    'taskVat',
    'taskInvoiceTo',
    'checklists',
    'taskInputPriceBilling',
    'taskPrestationName',
    'provider',
    'providerPrice',
    'rescheduleError'
  ]

  static values = {
    housing: { type: String, default: '' },
    prestation: { type: String, default: '' },
    newPath: String,
    locale: { type: String, default: 'fr' }
  }

  #stay = null
  #kind = null
  #housing = null
  #defaultStays = []

  async connect() {
    this.#kind = this.taskKindTarget.value
    this.#defaultStays = await apiFetch('stays')

    // Setting the housing without prestations so that it's not reset if the default housing's prestation
    // doesn't match the selected one
    if (this.housingValue.length) this.selectHousing({ withoutPrestations: true })
    if (this.prestationValue.length) this.#disablePrestationFields()
  }

  async selectStay() {
    if (!this.taskStayTarget.value) {
      this.taskHousingTarget.tomselect.clear()
      return this.filterStays(this.#defaultStays)
    }
    this.#stay = await apiFetch(`stays/${this.taskStayTarget.value}`)
    if (this.#kind == 'check_in') {
      this.changeDate(this.#stay.startDate)
    } else if (this.#kind == 'check_out') {
      this.changeDate(this.#stay.endDate)
    }

    if (this.taskHousingTarget.length) {
      this.changeHousing(this.#stay.housingId)
    }
  }

  async selectHousing(options) {
    this.#housing = this.hasTaskHousingTarget && this.taskHousingTarget?.tomselect?.getValue()
    this.updateChecklists()
    if (this.#housing === '' || this.#housing === undefined) {
      this.filterStays(this.#defaultStays) // reset default stay if housing is null
      this.#clearInvoiceFields()
      this.#clearAndEnablePrestationFields()
      return
    }

    let stays = await apiFetch('stays', {
      params: {
        filter: {
          housing_id: this.#housing
        }
      }
    })

    const selectedStay = stays.find(elem => elem.housingId == this.#housing)
    if (!selectedStay) this.taskStayTarget.tomselect.clear()
    this.filterStays(stays)

    if (!options?.withoutPrestations) {
      // Fetch housing prestation matching the given kind (cleaning, check_in, check_out)
      let housingPrestations = await apiFetch('housing-prestations', {
        params: {
          filter: {
            housing_id: this.#housing,
            tasks_template_id: this.tasksTemplateTarget.value
          }
        }
      })

      let prestations = await apiFetch('prestations')
      this.#filterPrestations(prestations)

      const selectedHousingPrestation = housingPrestations.find(elem => elem.housingId == this.#housing)

      // Populate invoice fields if a housing prestation is found
      if (selectedHousingPrestation) {
        this.#populateInvoiceFields(selectedHousingPrestation)
      } else {
        this.#clearInvoiceFields()
      }

      // Fetch prestation and populate prestation fields if a prestation is found
      if (selectedHousingPrestation?.prestationId) {
        let prestation = await apiFetch(`prestations/${selectedHousingPrestation.prestationId}`)
        this.#populateAndDisablePrestationFields(prestation)
        this.#populateProvidersFields(prestation)
      } else {
        this.#clearAndEnablePrestationFields()
        this.#clearProvidersFields()
      }
    }
  }

  async selectPrestation() {
    let prestation = await apiFetch(`prestations/${this.taskPrestationTarget.value}`)
    this.#populateAndDisablePrestationFields(prestation)
    this.#populateProvidersFields(prestation)
  }

  async updateChecklists() {
    const params = { task: { housing: this.#housing } }
    const url = new URL(this.newPathValue, window.location.origin)
    url.searchParams.set('task[housing_id]', this.#housing)
    url.searchParams.set('task[kind]', this.#kind)
    console.log(this.tasksTemplateTarget.value)
    url.searchParams.set('task[tasks_template_id]', this.tasksTemplateTarget.value)
    await fetch(url.toString(), {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
      .then(res => res.text())
      .then(data => (this.checklistsTarget.innerHTML = data))
  }

  updateManuallyScheduled() {
    this.manuallyScheduledTarget.value = true
    if (this.hasRescheduleErrorTarget) {
      this.rescheduleErrorTarget.classList.remove('tw-hidden')
    }
  }

  async clearAllInvoiceFields() {
    this.#clearInvoiceFields()
    this.#clearAndEnablePrestationFields()
    let prestations = await apiFetch('prestations')
    this.#filterPrestations(prestations)
    this.#clearProvidersFields()
  }

  changeDate(date) {
    this.taskDateTarget._flatpickr.setDate(date)
  }

  changeHousing(housingId) {
    this.taskHousingTarget.tomselect.addItem(housingId)
  }

  filterStays(stays) {
    stays = stays.map(stay => ({ value: stay.id, text: stay.displayTaskStay }))
    this.taskStayTarget.tomselect.clearOptions()
    this.taskStayTarget.tomselect.addOptions(stays)
  }

  #filterPrestations(prestations) {
    prestations = prestations.map(prestation => ({ value: prestation.id, text: prestation.prettyName }))
    this.taskPrestationTarget.tomselect.clearOptions()
    this.taskPrestationTarget.tomselect.addOptions(prestations)
  }

  #populateInvoiceFields(housingPrestation) {
    if (housingPrestation.invoicable) this.taskInvoiceToTarget.tomselect.setValue(housingPrestation.invoiceTo)
  }

  #clearInvoiceFields() {
    this.taskInvoiceToTarget.tomselect.clear(true)
    this.taskInputPriceBillingTargets.forEach(input => {
      input.classList.remove('disabled')
      if (input.classList.contains('select')) {
        input.closest('.input.select').querySelector('.ts-control').classList.remove('disabled')
      }
    })
  }

  #populateAndDisablePrestationFields(prestation) {
    this.taskPrestationTarget.tomselect.setValue(prestation.id, true)

    this.taskPrestationNameTarget.value = prestation.prettyName

    this.taskPricePretaxTarget.value = this.#formatWithLocale(parseFloat(prestation.pricePretax))
    this.taskPriceIncludingTaxTarget.value = this.#formatWithLocale(parseFloat(prestation.priceIncludingTax))
    this.taskVatTarget.tomselect.setValue(prestation.vat)

    this.#disablePrestationFields()
  }

  // Keeping it for later use

  #populateProvidersFields(prestation) {
    this.providerPriceTarget.value = prestation.providerPrice

    // prestation.userPrestations.forEach((userPrestation) => {
    //   let checkbox = this.providerTargets.find(provider => parseInt(provider.dataset.userId) === parseInt(userPrestation.user_id))
    //   if (checkbox && !checkbox.checked) {
    //     if (userPrestation.price) {
    //       checkbox.value = userPrestation.price
    //     } else {
    //       checkbox.value = prestation.defaultProviderPrice
    //     }
    //   }
    // })
  }

  #clearProvidersFields() {
    this.providerPriceTarget.value = ''
    // this.providerTargets.forEach(provider => provider.value = '')
  }

  #disablePrestationFields() {
    ;[this.taskPricePretaxTarget, this.taskPriceIncludingTaxTarget, this.taskPrestationNameTarget].forEach(field => {
      field.setAttribute('readonly', true)
    })
    ;[this.taskPrestationTarget, this.taskVatTarget].forEach(select => {
      select.tomselect.lock()
    })

    this.taskInputPriceBillingTargets.forEach(input => {
      input.classList.add('disabled')
      if (input.classList.contains('select')) {
        input.closest('.input.select').querySelector('.ts-control').classList.add('disabled')
      }
    })
  }

  #clearAndEnablePrestationFields() {
    this.taskPrestationNameTarget.value = ''
    ;[this.taskPricePretaxTarget, this.taskPriceIncludingTaxTarget, this.taskPrestationNameTarget].forEach(field => {
      field.value = ''
      field.removeAttribute('readonly')
    })
    ;[this.taskPrestationTarget, this.taskVatTarget].forEach(select => {
      select.tomselect.clear(true)
      select.tomselect.unlock()
    })
  }

  #formatWithLocale(value) {
    if (this.localeValue === 'fr') {
      return value.toFixed(2).replace('.', ',')
    } else {
      return value.toFixed(2)
    }
  }
}
