import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['input', 'toggle']

  static values = {
    state: Boolean
  }

  stateDom = new StateDomElement()
  connect() {}

  /**
   * Toggle the state of the toggle element and its input element
   *
   * This function is called when the controller is connected and when the
   * stateValue changes.
   *
   */
  update() {
    this.inputTarget.value = !this.stateValue
    this.stateValue = !this.stateValue
    this.changeToggle(this.stateValue)
  }

  /**
   * Toggle the active state of the toggle element
   * @param {boolean} state - State of the toggle element
   */
  changeToggle(state) {
    if (state) {
      this.stateDom.addClass(this.toggleTarget, ['is-active', 'tw-rotate-180'])
    } else {
      this.stateDom.removeClass(this.toggleTarget, ['is-active', 'tw-rotate-180'])
    }
  }
}