import { Controller } from 'stimulus'
import Kitsu from 'kitsu'
import LocalStore from 'helpers/localStore'
import StateDomElement from 'helpers/stateDomElement'
import SizeElement from 'helpers/sizeElement'
import { ManageSource } from '../../calendars/stays/utils/manageSource'
import { ManageUserExperiences } from '../../calendars/stays/manageUserExperiences'
import { InitCalendarStayOccupation } from '../../calendars/stays/initCalendarStayOccupation'

export default class extends Controller {
  static targets = ['calendarContainer', 'calendarList', 'createAvailability', 'createStay']

  static values = {
    date: String
  }

  dataHousingsCalendar = ''
  dataStaysCalendar = ''
  dataRateAvailabilities = []
  calendar = ''
  scrollTop = ''

  async connect() {
    this.store = new LocalStore()
    this.store.clear()
    const fetchApi = new Kitsu({
      baseURL: '/api'
    })
    this.userExp = new ManageUserExperiences()
    const fetch = this.fetchData()
    this.dataHousingsCalendar = await this.fetching(currentOffset => {
      return fetchApi.get('housings', {
        params: {
          filter: fetch.filterHousings,
          page: {
            limit: 100,
            offset: currentOffset
          }
        }
      })
    })
    this.dataStaysCalendar = await this.fetching(currentOffset =>
      fetchApi.get('stays', {
        params: {
          filter: {
            ...fetch.filterStays,
            monthly: this.dateValue
          },
          page: {
            limit: 100,
            offset: currentOffset
          }
        }
      })
    )
    this.dataRateAvailabilities = await this.fetching(currentOffset =>
      fetchApi.get('rate-and-availabilities', {
        params: {
          filter: { ...fetch.filterRatesAvailabilities, monthly: this.dateValue },

          page: {
            limit: 100,
            offset: currentOffset
          }
        }
      })
    )
    this.dataRateAvailabilities = this.dataRateAvailabilities.data.filter(data => data.availability === false)
    this.dataStaysCalendar = this.assignGuestCalendarName(this.dataStaysCalendar.data)
    this.initCal = new InitCalendarStayOccupation(
      this.dataHousingsCalendar.data,
      this.dataStaysCalendar,
      this.dataRateAvailabilities,
      this.calendarContainerTarget
    )
    this.stateDom = new StateDomElement()
    this.initCal.initCalendar()
    this.initCal.addEventsJsInCalendar()
    const sourceManage = new ManageSource()
    sourceManage.dispatchStaySource(this.dataStaysCalendar)
    document.querySelector('body').style.height = 'auto'
    this.userExp.displayBlockedCell()
    this.userExp.changeColorOfTodayCell()
    if (window.innerWidth <= 576) {
      const calendarLegend = document.querySelector('.calendar-legend')
      const calendar = document.querySelector('.fc-view-harness')
      this.sizeEl = new SizeElement()
      const availableHeight = this.sizeEl.getAvailableHeightAfterEl(calendarLegend) - 50
      this.sizeEl.applyRemainingHeight(calendar, availableHeight)
    }
  }

  addActionSidepanelCloseBtn() {
    const closeBtn = document.querySelector('.sidepanel-cross')
    this.stateDom.setAt(
      closeBtn,
      'data-action',
      'click->components--ui--sidepanel#closeSidepanel click->calendars--occupation-stays#disconnectController'
    )
  }

  /**
   * Date 11/12/2024
   * I iterate on all stays, if the stay doesn't have a guest then I add a default guestCalendarName.
   * @param {Array} stays - Array of objects - Array who contains all stays in calendar
   * @returns - Array of objects - Array who contains all stays in calendar. If guestCalendarName = ''If guestCalendarName = '' then I apply a default value.
   */
  assignGuestCalendarName(stays) {
    return stays.map(stay => {
      if (stay.guestCalendarName === '') {
        let notAssignedLabel = window.I18n === 'fr' ? 'Aucun client' : 'No guest'
        return {
          ...stay,
          guestCalendarName: notAssignedLabel
        }
      }
      return stay
    })
  }

  createAvailabilityLink(e) {
    const linkValue = e.currentTarget.closest('#createAvailability').href
    this.userExp.completeLinkAvailability(e.currentTarget.closest('#createAvailability'), linkValue)
  }

  disconnectController() {
    super.disconnect()
    this.store.remove('staySelected')
  }

  /**
   * Date 04/12/2024
   * Fetch data for housings, stays and rate and availabilities
   * @returns An object containing the filters for housings, stays and rate and availabilities
   */
  fetchData() {
    let housingIds = []
    let housingNamePrivate = ''
    const searchField = document.querySelector('.search-field')
    const inputsHidden = searchField.querySelectorAll('input')
    const checkboxChecked = document.querySelectorAll('.checkbox-list-content .checkbox-input:checked')
    checkboxChecked.forEach(input => {
      housingIds.push(input.value)
    })
    inputsHidden.forEach(input => {
      if (input.name === 'housing_search') {
        housingNamePrivate = input.value
      }
    })
    const filterHousings = this.filterByHousings(housingNamePrivate, housingIds)
    const filterStays = this.filterByStays(housingIds)
    const filterRatesAvailabilities = this.filterByRateAndAvailabilities(housingIds)
    return { filterHousings: filterHousings, filterStays: filterStays, filterRatesAvailabilities: filterRatesAvailabilities }
  }

  async fetching(request) {
    let result = { data: [] }
    let offset = 0
    let currentData = []
    do {
      currentData = []
      const response = await request(offset)
      offset += 100
      result.data = result.data.concat(response.data)
      currentData = response.data
    } while (currentData.length === 100) // If less than 100 items are fetched, it means we've reached the last page
    return result
  }

  /**
   * Date 04/12/2024
   * Filters housings by user search or/and housings selected in multiselect
   * @param {String} nameQuery - Search query tap by user
   * @param {Array} ids - Ids of housings selected in multiselect
   * @returns An object containing the filters for housings
   */
  filterByHousings(nameQuery = '', ids = '') {
    const filtersHousings = {}
    if (nameQuery !== '') {
      filtersHousings['namePrivate'] = nameQuery
    }
    if (ids !== '') {
      filtersHousings['id[]'] = ids
    }
    return filtersHousings
  }

  /**
   * Date 04/12/2024
   * Filters rate and availabilities by housings selected in multiselect
   * @param {Array} ids - Ids of housings selected in multiselect
   * @returns An object containing the filters for rate and availabilities
   */
  filterByRateAndAvailabilities(ids) {
    const filterRatesAvailabilities = {}
    if (ids.length > 0) {
      filterRatesAvailabilities['housingId[]'] = ids
    }
    return filterRatesAvailabilities
  }

  /**
   * Date 04/12/2024
   * Filters stays by housings selected in multiselect
   * @param {Array} ids - Ids of housings selected in multiselect
   * @returns An object containing the filters for stays
   */
  filterByStays(ids) {
    const filtersStays = {}
    if (ids.length > 0) {
      filtersStays['housingId[]'] = ids
    }
    return filtersStays
  }
}
