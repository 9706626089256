import { ManageData } from './manageData'
import { format } from 'date-fns'
// UTILS FUNCTIONS
import LocalStore from 'helpers/localStore'
import StateDomElement from 'helpers/stateDomElement'
// USER EXPERIENCE
import { ManageUserExperiences } from './manageUserExperiences'
import { UiUxCalendarStayOccupation } from './uiUxStayCalendar'
// FULL CALENDAR
import { Calendar } from '@fullcalendar/core'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction' // for selectable

export class InitCalendarStayOccupation {
  /**
   * This class has responsIble to init calendar stay occupation and configs
   * @param {Array} - All the housings you need to generate calendar
   * @param {Array} - All the stays you need to generate calendar event
   * @param {Array} - All the availabilities you need to generate calendar event availabilities
   * @param {HTMLElement} -  HTML element corresponding to the calendar container
   *
   */
  constructor(dataHousings, dataStays, dataAvailabilities, calendar) {
    this.dataHousings = dataHousings
    this.dataStays = dataStays
    this.dataAvailabilities = dataAvailabilities
    this.calendar = calendar
    this.calendarContainer = document.getElementById('stays_main')
    this.scrollTop = 0
    this.stateDom = new StateDomElement()
    this.userExp = new ManageUserExperiences()
    this.store = new LocalStore()
    this.uiUxExp = new UiUxCalendarStayOccupation(dataStays)
  }

  /**
   * Init and render the calendar
   */
  initCalendar() {
    this.calendar = new Calendar(this.calendar, this.getCalendarConfig())
    this.calendar.render()
    window.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY
    })
  }

  addEventsJsInCalendar() {
    this.uiUxExp.createLinkForEachStayEvent()
  }

  /**
   * Get all data necessary to generate calendar and config this
   **/

  getCalendarConfig() {
    const manageHousingsData = new ManageData(this.dataHousings)
    const manageStaysData = new ManageData(this.dataStays)
    const manageRateAndAvailabilities = new ManageData(this.dataAvailabilities)
    const calendarContainerHeader = this.stateDom.getAt(this.calendarContainer, 'data-stay-resource-label')
    const calendarDate = this.stateDom.getAt(this.calendarContainer, 'data-filter-date')
    const tooltip = document.querySelector('.calendar-list')
    let tooltipVisible = false
    return {
      plugins: [interactionPlugin, resourceTimelinePlugin],
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      locale: `${window.I18n}`,
      eventMinWidth: 5,
      slotLabelFormat: [{ weekday: 'narrow', day: '2-digit' }],
      initialDate: calendarDate,
      initialView: 'resourceTimeline',
      slotDuration: '24:00',
      duration: { days: 29 },
      now: new Date(calendarDate),
      resources: manageHousingsData.formatResources(),
      eventSources: [manageStaysData.formatEvent(), manageRateAndAvailabilities.formatAvailabilities()],
      selectable: true,
      resourceAreaWidth: '20%',
      resourceAreaHeaderContent: calendarContainerHeader,
      resourceOrder: 'title',
      handleWindowResize: true,

      // All ux behaviors when user select dates in calendar grid
      select: info => {
        const endDate = info.end
        const endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
        info.endStr = endDateFormatted
        this.store.set('idRowSelected', info.resource._resource.id)
        this.userExp.createStayOrNot(info)
        this.userExp.manageBlockedCell(info)
        this.store.set('selectedData', info)
        this.uiUxExp.createLinkForCreateStay()
        const selectionArea = document.querySelector('.fc-highlight')
        this.stateDom.addClass(selectionArea, ['tw-relative'])
        this.userExp.getDataStay(info)
        this.uiUxExp.cellBLockedInSelection(info.resource._resource.id)
        this.uiUxExp.checkIfUserSelectedUnavailabilities(info.resource._resource.id)
        document.querySelector('[data-jana-calendar-modal-target="calendarListBtn"]').click()
        this.uiUxExp.displayDatesSelected()
      }
    }
  }
}
